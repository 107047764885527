import React, { useEffect, useState, useRef } from 'react';
import Hls from 'hls.js';
import './player.css';
import socket from '../socket/socket';

const Player = () => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [isStreamActive, setIsStreamActive] = useState(false);
  const [streamUrl, setStreamUrl] = useState(null);
  const [streamStartTime, setStreamStartTime] = useState(null);
  const [isLoading] = useState(false);

  const [elapsedTime, setElapsedTime] = useState(null);

  const [viewers, setViewers] = useState(0);


 
  const apiUrl = process.env.REACT_APP_SOCKET_SERVER_URL;

  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = () => {
      fetch(`/config.json?_=${Date.now()}`, {
        cache: 'no-store',
      })
        .then((response) => response.json())
        .then((data) => setConfig(data))
        .catch((err) => console.error('Failed to load config'));
    };

    fetchConfig();

    const intervalId = setInterval(() => {
      fetchConfig();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    socket.on('streamStatus', (data) => {
      if (data.status === 'success') {
        setStreamUrl(data.streamUrl);
        setIsStreamActive(true);
        setViewers(data.viewers || 0);
  
        if (data.streamStartTime) {
          const startTime = parseInt(data.streamStartTime, 10);
          const interval = setInterval(() => {
            setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
          }, 1000);
  
          return () => clearInterval(interval);
        }
      } else {
        setIsStreamActive(false);
        setStreamUrl(null);
        setViewers(0);
        setElapsedTime(null); 
      }
    });

    if (isStreamActive) {
      const checkStream = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/checkStream`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          if (data.status === 'success') {
            setStreamUrl(data.streamUrl);
            setStreamStartTime(Date.now());
          } else {
            setIsStreamActive(false);
            setStreamUrl(null);
          }
        } catch (error) {
          console.error('Stream check failed:', error);
          setIsStreamActive(false);
        }
      };
    
      checkStream();
    }    
    return () => {
      setElapsedTime(null); 
    };
  }, [isStreamActive, apiUrl]);

  useEffect(() => {
    if (isStreamActive && streamStartTime) {
    }
  }, [isStreamActive, streamStartTime]);

  useEffect(() => {
    if (isStreamActive && streamUrl && videoRef.current) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hlsRef.current = hls;
        const video = videoRef.current;

        hls.loadSource(streamUrl);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play().catch(() => {});
        });

        hls.on(Hls.Events.ERROR, function () {});

        return () => {
          hls.destroy();
          hlsRef.current = null;
        };
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = streamUrl;
        videoRef.current.addEventListener('loadedmetadata', () => {
          videoRef.current.play().catch(() => {});
        });
      }
    } else {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = '';
      }
    }
  }, [isStreamActive, streamUrl]);

  if (!config) {
    return <div>Loading...</div>;
  }

  const streamName = config.streamname?.name || 'Stream test';
  const avatarPath = config.ava_streamer_path?.name || 'https://via.placeholder.com/80';
  const streamerName = config.streamer?.name || 'StreamerName';
  const streamTopic = config.theamestream?.name || 'Awesome Stream Topic';
  const pausePhoto = config.streampause?.path || null;




  return (
    <div className="video-section">
      {isStreamActive && (
      <div className="title-container">
        <h1 className="title-3d">{streamName}</h1>
      </div>
      )}

      <div className="player-container">
        {!isStreamActive && pausePhoto ? (
          <img src={pausePhoto} alt="Stream Paused" className="pause-photo" />
        ) : (
          <video
            ref={videoRef}
            width="100%"
            height="auto"
            controls
            autoPlay
            playsInline
            muted={false}
          />
        )}

        {isStreamActive && (
          <div className="live-indicator">
            <span className="dot"></span> Live
          </div>
        )}

        {!isStreamActive && (
          <div className="stream-status">
            
          </div>
        )}

        {isLoading && <div className="loading-indicator">Loading...</div>}
      </div>
      {isStreamActive && (
  <div className="streamer-info">
    <div className="avatar-section">
      <div className="avatar-wrapper">
        <img src={avatarPath} alt="Avatar" className="avatar" />
        <span className="live-badge">LIVE</span>
      </div>
      <div className="stream-details">
        <div className="streamer-name">{streamerName}</div>
        <div className="stream-topic">{streamTopic}</div>
      </div>
    </div>
    <div className="viewers-info">
    <span className="timer">
  ⏱ {Math.floor(elapsedTime / 3600)}:
  {String(Math.floor((elapsedTime % 3600) / 60)).padStart(2, '0')}:
  {String(elapsedTime % 60).padStart(2, '0')}
</span>

      <span className="viewers-icon">👁️</span> {viewers}
    </div>
  </div>
)}   
    </div>
  );
};

export default Player;
