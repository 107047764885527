import React, { useState, useEffect } from 'react';
import './App.css';
import Player from './player/Player';
import Chat from './chat/Chat';
import Threads from './threads/threads';
import Header from './Header';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150); 
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        {isMobile ? (
          <>
            <Player />
            <Chat />
            <Threads />
          </>
        ) : (
          <>
            <div className="left-panel">
              <Player />
              <Threads />
            </div>
            <div className="right-panel">
              <Chat />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
