import React, { useState, useEffect, useRef } from 'react';
import './chat.css';
import EmojiPicker from 'emoji-picker-react';
import DOMPurify from 'dompurify';  
import socket from '../socket/socket';
import Swal from 'sweetalert2';

const Chat = () => {
  const [isStreamActive, setIsStreamActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [username, setUsername] = useState('');
  const [userColor, setUserColor] = useState('');
  const [isUserNamed, setIsUserNamed] = useState(false);
  const [isNicknameValid, setIsNicknameValid] = useState(true);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const chatBoxRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const [canSendMessage, setCanSendMessage] = useState(true);
  const [isChatAvailable, setIsChatAvailable] = useState(true);



  useEffect(() => {
    const checkIsMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);


  useEffect(() => {
    socket.on('chatCleared', () => {
      setMessages([]); 
    });
  
    return () => {
      socket.off('chatCleared');
    };
  }, []);

  useEffect(() => {
    socket.on('chatNotAllowed', () => {
      setIsChatAvailable(false);
      Swal.fire({
        title: 'Chat is now unavailable',
        text: 'Chat is now unavailable',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    });
  
    socket.on('chatAvailable', () => {
      setIsChatAvailable(true);
    });
  
    return () => {
      socket.off('chatNotAllowed');
      socket.off('chatAvailable');
    };
  }, []);
  
  

  
  useEffect(() => {

    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (args[0] && typeof args[0] === 'string' && args[0].includes('wss://')) {
        return;  
      }
      originalConsoleError(...args);
    };



    socket.on('streamStatus', (data) => {
      if (data.status === 'success') {
        setIsStreamActive(true);
      } else {
        setIsStreamActive(false);
        setIsUserNamed(false);
        localStorage.removeItem('username');
        localStorage.removeItem('userColor');
      }
    });

    
    socket.on('chatMessages', (messages) => {
      setMessages(messages);
    });
  
    socket.on('newMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });



    const storedUsername = localStorage.getItem('username');
    const storedUserColor = localStorage.getItem('userColor');

    if (storedUsername && storedUserColor) {
      const sanitizedUsername = DOMPurify.sanitize(storedUsername);
      setUsername(sanitizedUsername);
      setUserColor(storedUserColor);
      setIsUserNamed(true);
      socket.emit('newUser', { username: sanitizedUsername, color: storedUserColor });
    }

    return () => {
      socket.off('streamStatus');
      socket.off('chatMessages');
      socket.off('newMessage');
    };
  }, []);

  useEffect(() => {
    if (isUserScrolling && chatBoxRef.current) { 
      const handleScroll = () => {
        if (chatBoxRef.current) {
          const isAtBottom = Math.abs(chatBoxRef.current.scrollHeight - chatBoxRef.current.scrollTop - chatBoxRef.current.clientHeight) < 1;
          setIsUserScrolling(!isAtBottom);
      
          if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
          }
      
          scrollTimeoutRef.current = setTimeout(() => {
            setIsUserScrolling(false);
          }, 3000);
        }
      };
      
      chatBoxRef.current.addEventListener('scroll', handleScroll);
      
      return () => {
        if (chatBoxRef.current) {
          chatBoxRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [isUserScrolling]);

  useEffect(() => {
    if (!isUserScrolling && chatBoxRef.current) { 
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isUserScrolling]);

  const handleSendMessage = () => {
    if (!canSendMessage) {
      Swal.fire({
        title: 'Slow down!',
        text: 'You can only send one message every 5 seconds!',
        icon: 'warning',
        timer: 5000,
        showConfirmButton: false,
        timerProgressBar: true,
        customClass: {
          popup: 'custom-popup',
        },
      });
      return;
    }
  
    if (newMessage.trim() !== '') {
      if (newMessage.length > 50) {
        Swal.fire({
          title: 'Too long!',
          text: 'Messages cannot exceed 50 characters.',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
          timerProgressBar: true,
          customClass: {
            popup: 'custom-popup',
          },
        });
        setNewMessage(''); 
        return;
      }
  
      const urlPattern = /\b(?:https?:\/\/|www\.)?[a-zA-Z0-9а-яА-ЯёЁ-]+\s*\.?\s*(com|ua|us|net|org|gov|edu|info|top|site|biz|site|xyz|icu|vip|io|ru|de|jp|fr|it|co|uk|ca|au|in|ch|nl|se|es|no|br|ar|pl|cz|fi|tr|mx|gr|kr|za|dk|pt|at|be|eu)\b(?:\/[^\s]*)?/iu;


  
      const scriptPattern = /<script.*?>.*?<\/script>|javascript:|eval\((.*?)\)|setTimeout\((.*?)\)|setInterval\((.*?)\)|window\.(location|open|alert|confirm|prompt|eval|setTimeout|setInterval)|document\.(cookie|location|write|open|close|getElementById|getElementsByTagName|createElement|write)|console\.(log|warn|error|info|debug)|location\.(href|replace)|fetch\((.*?)\)|XMLHttpRequest|on\w+=".*?"|[a-zA-Z0-9_]+\s*=\s*new\s+[a-zA-Z0-9_]+\s*\(.*?\)|\balert\((.*?)\)|\bconfirm\((.*?)\)|\bprompt\((.*?)\)/i;
  
      
  
      const sanitizedMessage = DOMPurify.sanitize(newMessage);

      if (urlPattern.test(sanitizedMessage) || scriptPattern.test(sanitizedMessage)) 
        {
        Swal.fire({
          title: 'Error!',
          text: "Messages like this aren't allowed in the chat.",
          icon: 'error',
          timer: 5000,
          showConfirmButton: false,
          timerProgressBar: true,
          customClass: {
            popup: 'custom-popup',
                       },
                });
        setNewMessage('');
        return;
      }

  
      const messageToSend = {
        username,
        message: sanitizedMessage,
        color: userColor,
      };
  
      socket.emit('newMessage', messageToSend);
      setNewMessage('');
  
      setCanSendMessage(false);
      setTimeout(() => {
        setCanSendMessage(true);
      }, 5000);
    }
  };
  
  
  
  

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleApplyUsername = () => {
    const usernameRegex = /^[a-zA-Z0-9\s]+$/;
  
    if (username.trim() && usernameRegex.test(username)) {
      const color = getRandomColor();
      setUserColor(color);
      setIsUserNamed(true);
      setIsNicknameValid(true);
  
      socket.emit('newUser', { username, color });
  
      localStorage.setItem('username', username);
      localStorage.setItem('userColor', color);
    } else {
      setIsNicknameValid(false);
    }
  };
  
  

  const handleEmojiClick = (emojiData) => {
    setNewMessage((prevMessage) => prevMessage + emojiData.emoji);
    setIsEmojiPickerVisible(false);
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerVisible(!isEmojiPickerVisible);
  };

  const handleScroll = () => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      const isAtBottom = Math.abs(chatBox.scrollHeight - chatBox.scrollTop - chatBox.clientHeight) < 1;
      setIsUserScrolling(!isAtBottom);
  
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
  
      scrollTimeoutRef.current = setTimeout(() => {
        setIsUserScrolling(false);
      }, 3000);
    }
  };

    return (
      <div className="chat-container">
     
        {!isChatAvailable ? (
          <div className="chat-unavailable">
            <div className="chat-unavailable-message">
              <span role="img" aria-label="lock">🔒</span> Chat isn't available now
            </div>
          </div>
        ) : (
          <>
          
            {isStreamActive ? (
            
              !isUserNamed ? (
                <div className="username-prompt">
                  <div className="username-prompt-header">
                    <span role="img" aria-label="lock">🔒</span> Introduce yourself in this chat
                  </div>
                  <input
                    className="chat-input"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Please, enter your nickname"
                  />
                  <button className="apply-button" onClick={handleApplyUsername}>
                    Apply
                  </button>
                  {!isNicknameValid && <div className="error-message">Please, enter your nickname</div>}
                </div>
              ) : (
                <>
                
                  {!isMobile && (
                    <div className="chat-header">
                      Chat room
                    </div>
                  )}
    
            
                  <div className="chat-box" ref={chatBoxRef} onScroll={handleScroll}>
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`chat-message ${message.username === username ? 'sent' : 'received'}`}
                      >
                        <div
                          className="message-username"
                          style={{ color: message.color }}
                        >
                          {message.username}:
                        </div>
                        <div className="message-text-time">
                          <div className="message-text">{message.message}</div>
                        </div>
                      </div>
                    ))}
                  </div>
    
                  <div className="chat-input-container">
                    <div className="input-with-emoji">
                    
                      {!isMobile && (
                        <>
                          <button className="emoji-button" onClick={toggleEmojiPicker} disabled={!isStreamActive}>
                            😊
                          </button>
                          {isEmojiPickerVisible && isStreamActive && (
                            <div className="emoji-picker-container">
                              <EmojiPicker onEmojiClick={handleEmojiClick} />
                            </div>
                          )}
                        </>
                      )}
    
                  
                      <input
                        className="chat-input"
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type something.."
                        disabled={!isStreamActive}
                      />
                    </div>
             
                    <button className="send-button" onClick={handleSendMessage} disabled={!isStreamActive}>
                      Send
                    </button>
                  </div>
                </>
              )
            ) : (
              <div className="chat-unavailable">
                <div className="chat-unavailable-message">
                  <span role="img" aria-label="lock">🔒</span> Chat isn't available now
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
    
};

export default Chat;
