import React, { useState, useEffect } from 'react';
import './header.css';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import logo from './logo.png';

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = () => {
      fetch(`/config.json?_=${Date.now()}`, {
        cache: 'no-store'
      })
        .then(response => response.json())
        .then(data => {
          setConfig(data);
        });
    };

    fetchConfig();

    const intervalId = setInterval(() => {
      fetchConfig();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!config) {
    return null;
  }

  const tgLink = config.links.find(item => item.soc === 'tg')?.link || '#';
  const supLink = config.links.find(item => item.soc === 'sup')?.link || '#';
  const twitterLink = config.links.find(item => item.soc === 'twitter')?.link || '#';

  const howItWorksText = config.howitworks?.desc || 'No description';
  const steps = [];
  for (let i = 1; i <= 15; i++) {
    const stepKey = `step${i}`;
    if (config.howitworks && config.howitworks[stepKey]) {
      steps.push(config.howitworks[stepKey]);
    }
  }

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Pump Fun Logo" className="logo" />
      </div>

      <div className="menu">
        <button className="how-it-works" onClick={toggleModal}>[How it works]</button>
        <button
  className="how-it-works"
  onClick={() => window.open(supLink, '_blank')}
>
  [Support]
</button>
        <div className="social-icons">
          <a href={tgLink} target="_blank" rel="noopener noreferrer">
            <FaTelegramPlane size={24} />
          </a>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <FaTwitter size={24} />
          </a>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>How it works</h2>
            <p className="how-desc">{howItWorksText}</p>
            {steps.map((stepText, index) => (
              <p className="how-step" key={index}>{stepText}</p>
            ))}
            <button className="ready-button" onClick={toggleModal}>[I'm ready to pump]</button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
