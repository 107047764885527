import React, { useEffect, useState } from 'react';
import './threads.css';
import socket from '../socket/socket';

const Threads = () => {
  const [threads, setThreads] = useState([]);
  const [config, setConfig] = useState(null);
  const [isStreamActive, setIsStreamActive] = useState(false);


  socket.on('streamStatus', (data) => {
    if (data.status === 'success') {
      setIsStreamActive(true);
    } else {
      setIsStreamActive(false);
    }
  });


  const fetchConfig = () => {
    fetch(`/config.json?_=${Date.now()}`, {
      cache: 'no-store',
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        setThreads(data.threads || []); 
      })
      .catch((err) => console.error('Failed to load config:', err));
  };

  useEffect(() => {
    fetchConfig();

    const intervalId = setInterval(() => {
      fetchConfig();
    }, 2000);

    return () => clearInterval(intervalId); 
  }, []);

 
  if (!threads || threads.length === 0 || !isStreamActive) {
    return null; 
  }

  return (
    <div className="threads-container">
      {threads.map((thread, index) => (
        <div className="thread-item" key={index}>
          <div className="thread-header">
            <img
              src={thread.img || 'https://via.placeholder.com/150'}
              alt="User Avatar"
              className="thread-mini-avatar"
            />
            <span className="thread-login-tag">{thread.login}</span>
            <span className="thread-time">{thread.time}</span>
          </div>
          <div className="thread-content-row">
            <div className="thread-image-container">
              <img
                src={thread.img || 'https://via.placeholder.com/150'}
                alt="Thread"
                className="thread-image"
              />
            </div>
            <div className="thread-text-container">
              <div className="thread-text">{thread.text}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Threads;
